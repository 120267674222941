@import 'custom_bootstrap_vars';
@import '~bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

// DARK MODE STUFF
// Dark theme colors
$dark-bg: #121212;
$dark-text: #e0e0e0;
$dark-primary: #bb86fc;
$dark-secondary: #03dac6;
$dark-error: #cf6679;

// Body and background
body {
  background-color: $dark-bg;
  color: $dark-text;
}

// Alerts
.alert {
  background-color: $dark-secondary;
  color: $dark-bg;
}

.alert-error {
  background-color: $dark-error;
  border-color: darken($dark-error, 15%);
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #2C3E50;
  color: #EAEAEA;
}

.dropdown-menu .dropdown-item:active {
  background-color: #34495E;
  color: #ECF0F1;
}

.flex-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

#popover-content .form-control {
  margin-bottom: 10px;
}

#collapse-sidebar-button {
  background-color: rgb(43, 48, 53);
  position: fixed;
  left: 260px;
  font-size: 20px;
  margin-top: 15px;
  border-radius: 24px;
  border: 0;
  transition: 0.1s;
  z-index: 12;
  box-shadow: 0 0 10px black;
}

#collapse-sidebar-button>i {
  text-shadow: 1px 1px 15px white;
  transition: 0.3s;
}

#collapse-sidebar-button>i:hover {
  text-shadow: 1px 1px 20px white;
}

#sidebarHolder {
  transition: 0.2s;
  width: 250px;
  flex-shrink: 0;
}

#mainSidebar {
  height: 100vh;
  width: 250px;
  position: fixed;
  padding-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  overflow-x: visible;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.404) rgb(43, 48, 53);
  background-color: rgb(43, 48, 53);
  transition: 0.2s;
  z-index: 11;
}


.sidebar-element {
  transition: 0.2s;
}

@media (max-width: 630px) {
  /* CSS that should be displayed if width is equal to or less than 630px goes here */
  #mainSidebar {
    padding-left: 50px;
    padding-right: 50px;
    width: 0px;
    display: none;
    position:fixed;
  }

  body.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  #sidebarHolder {
    transition: 0.2s;
    width: 0px;
  }

  #collapse-sidebar-button {
    left: 5px;
  }

  .main-page-content {
    margin-top: 50px;
  }

  #allauth-content-holder {
    width: 95% !important;
  }

  .alert {
    width: 95% !important;
  }


  #userSidebarDropdown {
    margin-left: 35px;
  }
}

@media (max-width: 1400px) {
  .flex-break {
    /* This makes it so that elements on the home page don't break when screen is resized */
    /* to see effect comment out the next line and resize the screen to below 1400px */
    flex-basis: 0% !important;
  }
}

/*
* Task Index styling
*/

#task-index-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

#task-index-container>.floor-item {
  width: 350px;
  margin: 10px;
}

.floors-index-room-redirect {
  text-decoration: none !important;
  color: inherit;
}

.floors-index-room-redirect>i {
  margin-left: 5px;
  display:none;
  transition: 0.5s;
}

/* Display eye icon on hover over a room */
.floors-index-room-redirect:hover>i {
  display:inline;
}

.floors-index-item {
  padding: 10px 10px 1px 20px;
  margin: 0 !important;
  transition: 0.333s ease-in-out;
}

.floors-index>.floors-index-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}


.floors-index-item:hover, .floors-index-item:nth-child(even):hover  {
  background-color: black;
}

.floor-item>.card {
  border: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  transition: 0.2s;
}

.floor-item>.card>.card-body {
  padding: 0;
}

.floor-item>.card>.card-header {
  border: 0;
}

.add-room-button {
  display:block;
  padding: 4px;
  width: 100px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: 0 0 20px rgba(13,110,253, 0.1);
  background-color: rgba(0,0,0,0);
  border:0;
  transition: 0.2s;
}

.add-room-button:hover {
  box-shadow: 0 0 20px rgb(13,110,253);
}

.add-room-form {
  display:none;
  transition: 0.2s;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: rgb(43, 48, 53);
}

.add-room-button-submit, .add-floor-btn-submit, .add-floor-button-submit {
  display: inline-block;
  width: 75px;
  margin-left: 30px;
  background-color: rgba(25,135,84, 0.6);
  box-shadow: 0 0 15px rgba(25,135,84, 0.6);
}

.add-room-button-submit:hover, .add-floor-btn-submit:hover, .add-floor-button-submit:hover {
  box-shadow: 0 0 20px rgba(25,135,84, 1);
}

.room-emoji {
  margin-left: 10px;
  padding:0;
  border:0;
  font-size:30px;
  text-shadow: 0 0 15px rgba(255, 255, 0, 0.315);
  transition: 0.3s;
}

.room-emoji:hover {
  background-color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 15px rgba(255, 255, 0, 0.822);
}

#add-floor-expand-btn {
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  border-radius: 30px;
  font-size: 20px;
}

#addFloorForm {
  width: 250px;
  position: fixed;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, -50%);
  background-color: rgb(33,37,41);
  box-shadow:
    0px 0px 10px black,
    0px 0px 20px white,
    0px 0px 30px black,;
  padding: 15px;
  border-radius: 15px;
  display:none;
}

.floor-emoji {
  margin-left: 10px;
  padding:0;
  border:0;
  font-size:30px;
  text-shadow: 0 0 15px rgba(255, 255, 0, 0.315);
  transition: 0.3s;
}

.floor-emoji:hover {
  background-color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 15px rgba(255, 255, 0, 0.822);
}

.edit-room-form {
  display: none;
}

.edit-floor-form {
  display:none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.842);
}

.edit-btn, .delete-btn {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
}

.add-floor-form {
  z-index:1009;
}

.help-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  background: 0;
  border: 0;
  padding: 0px 5px 0px 5px;
  font-size: 30px;
  color: cornflowerblue;
}

.help-btn-pulsing {
  animation: pulsing 3s ease-in-out infinite;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/*
* Allauth styling
*/

#allauth-content-holder {
  margin-top: 50px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#allauth-content-holder>form>button {
  box-shadow: 0 0 10px black;
  margin: 5px;
}

#allauth-content-holder>form {
  box-shadow: 0 0 10px black;
  margin-right: 20px;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 30px;
  background-color: rgb(43, 48, 53);
}

.alert {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
